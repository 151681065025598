@import "../../../../../../shared/variables.less";

.draggablePreview {
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: @drag-zindex;
  transition: background-image 0.1s;
}

.previewSize {
  height: 30vh;
  min-height: 300px;
  width: calc(30vh / 1.4);
  min-width: calc(300px / 1.4);
  border-radius: 5%;
}

.dragSize {
  height: 14vh;
  width: calc(14vh / 1.4);
  border-radius: 5%;
}

.hidden {
  display: none;
}

.facedownPreview {
  background-image: url("../../../../shared/card-back.png");
}