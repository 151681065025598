// need the "div." to exceed the specifity of the mui rules
div.createGameDialogContainer {
  border-radius: 12px;
  // background-color: #4b4657c7;
  background-color: #392e56;
  overflow: visible;

  min-height: 450px;

  * {
    box-sizing: border-box;
    font-family: 'open-sans', sans-serif !important;
  }
}

.createGameDialogTitle h2 {
  text-align: center;
  color: #f7efef;
  margin-bottom: 10px;
}

.createGameDialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: fit-content;
  min-width: 400px;
  min-height: 400px;

  padding: 0px 24px;

  font-family: 'open-sans', sans-serif;

  label {
    align-self: flex-start;
    color: #f7efef;
    display: block;
    margin-bottom: 5px;
  }

  .titleInput, .passwordInput {
    height: 35px;
    width: 300px;
    padding: 5px;
    padding-left: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }

  .maxPlayersContainer, .formatContainer, .experienceLevelContainer {
    margin-bottom: 20px;
    width: 300px;
    height: 55px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .rfs-select-container {
      margin-top: 25px;
      background-color: white;
      border-radius: 5px;
      // This is to let the dropdowns overflow beyond the dialog container
      // without triggering a scrollbar on it
      position: absolute;
      width: 300px;
    }
  
    .rfs-control-container > div > div {
      overflow: visible;
    }
  }

  .mirrorModeContainer {
    margin-bottom: 20px;

    label {
      display: unset;
    }
  }

  #submit {
    display: block;
    margin-bottom: 30px;
    background-color: #3f51b5;
  }
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}