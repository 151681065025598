div.sideboardDialog {
  background: #28234ab8;
  padding-left: 25px;
  color: white;
  border-radius: 12px;
  width: 650px;
  max-width: unset;

  & * {
    font-family: 'open-sans', sans-serif !important;
  }
}

.sideboardDialogContent {
  width: 35vw;
  height: 40vh;
  padding: 20px 24px;

  li {
    margin-bottom: 5px;
  }

  .itemQuantity {
    width: 40px;
    display: inline-block;
  }

  .itemName {
    text-transform: capitalize;
    display: inline-block;
  }
}