.roomsList {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;

  height: fit-content;
  max-height: 78vh;
  min-width: 600px;
  max-width: 1400px;
  padding-right: 5px;

  overflow-y: auto;
  overflow-x: hidden;

  &.halfList {
    @media screen and (max-height: 800px) {
      height: calc(28% - 50px);
      min-height: 250px;
    }
    @media screen and (min-height: 801px) and (max-height: 1400px) {
      height: calc(40% - 50px);
    }
    @media screen and (min-height: 1401px) {
      height: calc(55% - 50px);
    }
  }

  &.emptyState {
    height: 78vh;
    grid-template-columns: 1fr;
    text-align: center;
    overflow-y: auto;

    // creates white corners
    // background:
      // linear-gradient(to right, white 2px, transparent 2px) 0 0,
      // linear-gradient(to right, white 2px, transparent 2px) 0 100%,
      // linear-gradient(to left, white 2px, transparent 2px) 100% 0,
      // linear-gradient(to left, white 2px, transparent 2px) 100% 100%,
      // linear-gradient(to bottom, white 2px, transparent 2px) 0 0,
      // linear-gradient(to bottom, white 2px, transparent 2px) 100% 0,
      // linear-gradient(to top, white 2px, transparent 2px) 0 100%,
      // linear-gradient(to top, white 2px, transparent 2px) 100% 100%;

    // background-repeat: no-repeat;
    // background-size: 30px 30px;
  }

  .emptyStateMessage {
    color: #edebf2;
    font-family: 'open-sans', sans-serif;
    font-size: 18px;
    
    height: fit-content;
    position: relative;

    @media screen and (max-height: 1200px) {
      top: 35%;
    }
    @media screen and (min-height: 1201px) {
      top: 25%;
    }

    .emptyStateMainLabel {
      margin-bottom: 8px;
    }
  }

  .emptyStateCreateButton {
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    margin: auto;
  }
}