@currentRoomColor: #221f2a;

.currentWaitingRoomView {
  box-sizing: border-box;

  min-width: 900px;
  height: 46%;
  max-height: 275px;

  padding: 15px 20px;
  margin-top: 10px;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 66% 34%;
  grid-template-rows: 15% 1fr;

  color: #dedbe6;
  font-family: 'open-sans', sans-serif;

  background-color: transparent;
  box-shadow: 0px 3px 7px #00000070, inset 0px 0px 0px 20px @currentRoomColor;

  .titleAndTagsContainer {
    grid-row-start: 1;
    grid-row-end: 2;

    grid-column-start: 1;
    grid-column-end: 2;

    display: flex;
    align-items: center;
    justify-content: space-between;

    // this is needed for the chips for unclear reasons or they get cut off
    position: relative;

    padding-left: 12px;

    background-color: @currentRoomColor;

    .roomTitle {
      display: inline-block;
      font-size: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 350px;
      height: 25px; // prevents overflow: hidden from clipping descenders

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .roomTags {
      display: inline-block;
      margin-right: 10px;

      div {
        margin-right: 5px;
        background-color: #9175b44d;
      }

      & * {
        color: #edebf2;
        position: relative;
      }
    }
  }

  .roomDetails {
    grid-row-start: 1;
    grid-row-end: 3;

    grid-column-start: 2;
    grid-column-end: 3;

    background-color: @currentRoomColor;

    padding-left: 10px;

    .playerStatusPanel {
      padding: 10px;
      border-radius: 10px;
      background-color: #9175b44d;

      .roomStatus {
        margin-bottom: 10px;
      }

      hr {
        border-color: #ffffff63;
      }

      .playerList {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .playerIdLabel {
          vertical-align: text-bottom;
        }

        .deckSelectedIndicator {
          margin-left: 5px;

          * {
            padding: 0;
            cursor: auto;
          }

          svg {
            color: #edebf2;
          }
        }
      }
    }

    .roomControlsPanel {
      .middleRowContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;

        &.centerSelector {
          justify-content: space-around;
          padding: 15px;
        }

        svg {
          color: #edebf2;
          font-size: 32px;
          padding: 0;
        }
      }

      .bottomRowContainer {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
      }

      &.gameStarted {
        display: flex;
        justify-content: space-evenly;
        margin-top: 30px;
      }

      .launchGameButton, .rejoinGameButton, .leaveGameButton, .exitGameButton {
        display: inline-block;
        font-family: 'open-sans', sans-serif;
      }

      .launchGameButton, .rejoinGameButton {
        background-color: #3f51b5;
      }

      .leaveGameButton, .exitGameButton {
        background-color: #a84a4a;;
      }
    }
  }

  .roomChat {
    grid-row-start: 2;
    grid-row-end: 3;

    grid-column-start: 1;
    grid-column-end: 2;
  }
}