.trespassingOverlay {
  background-color: #4d3e8370;
  width: 100%;
  height: 100%;

  padding: 20px;
  box-sizing: border-box;

  .dottedBorder {
    width: 100%;
    height: 100%;
    border-style: dashed;
    border-color: #0000004d;
    border-width: medium;
  }

  .floatingNotification {
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 350px;
  }
}