.lifeCounterContainer {
  position: absolute;
  right: 0;

  font-size: clamp(16px, 2.0vh, 24px);
  line-height: clamp(22px, 2.4vh, 40px);
  height: clamp(22px, 2.4vh, 40px);
  width: 30%;
  margin-right: 15%;

  box-shadow: 2px 3px 3px #00000096;
  transform: skew(-0.5rad);
  border-radius: 3px;

  text-align: center;
  box-sizing: border-box;

  // <class>:nth-child does not select the nth instance of a class;
  // it matches the nth child of [the parent to the element that matches the class],
  // even if that child doesn't have the class. So we want 3 and 4 even
  // though there are only 2, because they're the 3rd and 4th children of their parent.
  &:nth-child(3) {
    bottom: 40%;
    background-color: #26d4d4;
  }

  &:nth-child(4) {
    bottom: 15%;
    background-color: #e96868;
  }

  & > button, & > span {
    display: inline-block;
    transform: skew(0.5rad);
  }

  & > button {
    height: 100%;
    padding: 0;

    position: relative;
    top: -2px;

    svg {
      width: unset;
      height: 100%;
    }
  }

  & > button:nth-child(1) {
    left: -2px;
  }

  & > button:nth-child(3) {
    right: -2px;
  }
}

.playerProfile .lifeCounterContainer {
  width: 35%;
  margin-right: 10%;
}