@import "../../../../shared/variables.less";

.pileContainer {
  box-sizing: border-box;
  height: calc(14vh + clamp(30px, 3vh, 60px));
  width: calc(10vh + clamp(30px, 3vh, 60px));
  padding: 0;
  margin: 5px;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  background-color: #3e4f70;
  box-shadow: 0px 1px 3px #3a3838 inset;

  // makes it so the label area can still be dropped onto
  .dropzone {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: clamp(15px, 1.5vh, 30px);
  }
}

.pileLabel {
  font-size: ~"max(1vh, 10px)";
  font-family: 'open-sans', Arial, sans-serif;
  user-select: none;
  color: white;
  
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  padding-top: 3px;
}

.pileCard {
  background-size: 100% 100%;
  height: 14vh;
  width: calc(14vh / 1.4);
  margin: auto;
  margin-top: 1px;
  // for some reason with a box-shadow on this you see white corners, so the border-radius
  // smooths those corners away. max() with vh for large/small screens as usual.
  border-radius: ~"max(4px, 0.4vh)";
  box-shadow: 1px 2px 4px #000000bd;
}

.pileSizeLabel {
  user-select: none;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 30%;
  padding: 4px 8px;

  position: absolute;
  left: 0;
  bottom: 0;

  // background: #282b4f;
  background: #3d3d3d;
  border: 2px solid #666;
  border-left: none;
  border-bottom: none;
  color: white;
  text-align: center;

  font-size: calc(14px + 0.1vw);
  font-family: Arial, sans-serif;

  &.bigPile {
    padding: 6px 6px;
  }

  &:hover {
    cursor: pointer;
    background: #4f4f4f;
    border: 2px solid rgb(224, 223, 223);
    border-left: none;
    border-bottom: none;
  }
}