.roomsListControls {
  color: white;
  min-width: 600px;
  max-width: 1380px;
  height: 30px;
  border-radius: 5px;
  margin-bottom: 20px;

  display: flex;
  justify-content: end;

  .MuiTouchRipple-rippleVisible {
    display: none;
  }

  .createGameButton {
    margin-right: 30px;
    font-family: 'open-sans', sans-serif;
    background-color: #3f51b5;
  }

  .titleFilter {
    margin-right: 30px;
    padding-left: 5px;

    &:focus {
      outline: none;
    }
  }

  .rfs-select-container {
    width: 140px;

    * {
      color: white;
      font-family: 'open-sans', sans-serif;
      box-shadow: none;
    }

    .rfs-option {
      color: black;
    }
  }

  .rfs-control-container {
    min-height: unset;
  }

  .rfs-control-container > div > div {
    overflow: visible;
  }
}