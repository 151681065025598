.signInDialogContainer {
  div.signInDialogTitle {
    padding-bottom: 12px;
  }

  .signUpForm {
    width: 300px;
    margin-bottom: 20px;

    .usernameField {
      margin-bottom: 10px;
    }

    .emailField {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .passwordField {
      margin-top: 0;
      margin-bottom: 5px;
    }

    .marginField, .signUp .signUpButton {
      display: block;
    }

    .signUpButton {
      margin-bottom: 10px;
    }
    
    .MuiTouchRipple-rippleVisible {
      display: none;
    }
    
    .loginOrSignupSwitchLabel { 
      font-family: Arial;
      vertical-align: middle;
    }

    .forgotPassword {
      display: block;
      margin: 15px 0px;
      padding: 0;
      text-transform: none;
      font-size: 16px;
      line-height: 16px;
    }
    
    .loginOrSignUpSwitch {
      display: inline;
      padding: 0;
      padding-left: 10px;
      text-transform: none;
      font-size: 16px;
      line-height: 16px;
    }
  }

  div.loadingBackdrop {
    z-index: auto;
  }
}