.lobbyContainer {
  width: 100vw;
  height: calc(100vh - 50px); // 50px is height of TopBar
  min-height: 500px;
  // need this line because of overflow from 100vw width
  // https://stackoverflow.com/questions/23367345/100vw-causing-horizontal-overflow-but-only-if-more-than-one
  max-width: 100%;

  display: grid;
  overflow: hidden;

  background-color: #0e0e20;
  background-image: url("./background.jpg");
  background-size: cover;

  @media screen and (max-width: 2200px) {
    grid-template-columns:
      0px
      clamp(250px, 32vh, 360px)
      minmax(0%, 1fr)
      clamp(220px, 32vh, 300px)
      0px;
  }

  @media screen and (min-width: 2201px) {
    grid-template-columns: 1fr 10vw 40vw 20vw 1fr;
  }

  & * {
    box-sizing: border-box;
  }

  .leftSidebarContainer {
    width: 100%;
    height: 100%;

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end:  4;
  }

  .centerContentContainer {
    // 50px for top bar
    // height needs to be set in a non-percent way, for the percent heights of its children
    height: calc(100vh - 50px);
    padding: 20px;

    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end:  4;
  }

  .rightSidebarContainer {
    width: 100%;
    height: 100%;
    // background-image: url("./ad.jpeg");
    background-repeat: no-repeat;
    background-position: right;

    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end:  4;
  }
}