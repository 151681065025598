.deckItemContainer {
  height: clamp(225px, 20vh, 300px);
  width: clamp(160px, calc(20vh/1.4), 214px);
  margin-bottom: 50px;

  border-radius: 8px;
  box-shadow: 2px 4px 6px 0px #000000b3;
  background-color: #7d758b57;
  backdrop-filter: blur(5px);

  &:hover {
    background-color: #c4b7db57;
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  .deckCoverImage {
    height: clamp(150px, 14vh, 220px);
    aspect-ratio: 0.715;

    position: relative;
    top: -12px;

    background-size: 100% 100%;
    border-radius: 4%;

    box-shadow: 2px 4px 6px 0px #161515c2;
  }

  .deckNameBanner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    text-align: center;
    padding: 8px 0px;

    // orange option to match sunset
    // background: linear-gradient(26deg, #843926, #ee673c);
    // lovely scarlet
    // background: linear-gradient(26deg, #5d1c30, #8e2345);
    // blue
    background: linear-gradient(26deg, #2c3182, #29a0c8);
    // green
    // background: linear-gradient(26deg, #1d411c, #1ea835);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}