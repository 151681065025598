@import url("../../../../shared/variables.less");

// All these classes have transition: top so that as the other classes
// are added and removed by CSSTransition, that aspect remains constant even as
// the transition value changes for other properties besides top.

.floatingNotification {
  position: absolute;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px #514c4c;
  user-select: none;
  z-index: @floatingNotification-zindex;
  transition: top 300ms;

  & > div {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.leftNotification {
  left: 3%;
  top: -13vh;
}

.rightNotification {
  text-align: center;
  top: -9vh;
  right: 4%;
}

.leftNotificationDoubleHeight {
  top: -16vh;
}

.floatingNotification-enter {
  opacity: 0;
  transform: translateX(15%);
  transition: top 300ms;
}

.floatingNotification-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 200ms, transform 200ms, top 300ms;
}

.floatingNotification-exit {
  opacity: 1;
  transition: top 300ms;
}

.floatingNotification-exit-active {
  opacity: 0;
  transition: opacity 200ms, top 300ms;
}