.leftSidebar {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 18vh 3vh 24vh 1fr 27vh 18vh;

  // controls its position within battlefield grid
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end:  4;

  background-color: #1c2c49;
  user-select: none;
}

.opponentProfile {
  color: white;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end:  2;

  display: flex;
  justify-content: center;
  align-items: center;
}

.opponentHandSize {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end:  3;

  font-size: calc(16px + 0.2vw);
  font-family: 'open-sans', Arial, sans-serif;
  user-select: none;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
}

.opponentPiles {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end:  4;

  display: flex;
  justify-content: center;
  align-items: center;
}

.middleArea {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end:  5;

  ul {
    box-sizing: border-box;
    height: 90%;
    background-color: #ffffff3d;
    list-style: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  ul li {
    display: inline-block;
    margin: 9px;

    width: 16%;
    min-height: 40px;
    max-height: 58px;

    aspect-ratio: 1;
  }

  // highly specific selector to override other stuff
  li button.utilityButton {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#66abe5), to(#015490));

    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #3a3838, inset 0px 2px 3px #58a4cc;
    color: #d2e6ea;

    &:hover {
      background-color: white;
    }

    &:active {
      box-shadow: 0px 2px 3px #3a3838 inset;
      padding-top: 10px;
    }
  }
}

.playerPiles {
  display: flex;
  justify-content: center;
  align-items: center;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 5;
  grid-row-end:  6;
}

.playerProfile {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 6;
  grid-row-end:  7;

  margin-top: -5%;

  display: flex;
  justify-content: center;
  align-items: center;
}