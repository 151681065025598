.powerToughnessModifier {
  user-select: none;
  font-family: 'Comfortaa';
  font-size: 14px;
  transform-origin: right bottom;
  position: absolute;
  bottom: -20px;
  right: 0;
}

.transitioning {
  .powerToughnessModifier {
    display: none;
  }
}

.tapped {
  .powerToughnessModifier {
    transform: rotate(-90deg);
    bottom: 100%;
    right: -20px;
  }
}