.transferListContainer {
  display: flex;
  flex-direction: row;
  min-width: 600px;
  user-select: none;

  .sideboardContainer, .decklistContainer {
    min-width: 250px;

    li {
      width: fit-content;

      .itemName:hover {
        cursor: pointer;
      }
    }
  }
}