.search-box {
  position: absolute;
  bottom: 1vh;
  right: 5px;
  background: #2f3640;
  border-radius: 20px;
  padding: 8px;
  z-index: 10;
}

.search-text {
  height: 24px;
}

.search-box-active > .search-text {
  width: 160px;
  padding: 0 6px;
}

svg.search-btn {
  color: #cacaca;
  float: right;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.search-text {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  font-weight: normal;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
}