.carouselDrawer {
  width: 100%;
  height: 100%;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end:  3;
  background-color: #40605e;
  position: relative;
  z-index: 99999;
}

.slider {
  transition: transform 0.3s ease-out;

  &:before, &:after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
  }
  &:before {
    box-shadow: -10px 0px 15px -15px inset;
    right: 0px;
    z-index: 100; // idk but it worked
  }
  &:after {
    box-shadow: 10px 0px 15px -15px inset;
    left: 0px;
  }
}

.sliderOpen {
  transform: translateY(-50%);
}

button.drawerCloseButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  color: #c8c8c8;

  & svg {
    height: 100%;
    width: 100%;
  }

  z-index: 10000; // shouldn't a child of a z-indexed element inherit that quality? wtf?
  // research this later, cuz we shouldn't need this property imo
}