.profileContainer {
  position: relative;

  font-family: 'open-sans', sans-serif;
  color: black;

  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 10px;

  opacity: 0.8;


  .playerAvatar {
    position: absolute;
    left: 8%;
    top: 50%;
    transform: translateY(-50%);

    aspect-ratio: 1;
    width: clamp(100px, 42%, 160px);

    box-shadow: 2px 3px 3px #00000096;
    border-radius: 50%;
  }

  .playerName {
    position: absolute;
    top: 16%;
    right: 0;
    left: 0;
    z-index: -1;

    font-size: clamp(16px, 2.0vh, 24px);
    line-height: clamp(22px, 2.4vh, 40px);
    height: clamp(22px, 2.4vh, 40px);
    width: 90%;

    margin: auto;
    text-align: end;
    box-sizing: border-box;

    background-color: #1cbfff;

    box-shadow: 2px 3px 3px #00000096;
    transform: skew(-0.5rad);
    border-radius: 3px;

    .playerNameText {
      display: inline-block;
      transform: skew(0.5rad);

      margin-left: 50%;
      width: 50%;

      text-align: center;
    }
  }
}