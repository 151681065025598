.cardCarouselContainer {
  background: #304771;
  text-align: center;
  position: relative;
}

.widthLimiter {
  width: 70%;
  height: 18vh;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin: auto;
  transition: width 0.3s;
  position: relative;

  .carouselSizeLabel, .sortHandButton {
    // https://stackoverflow.com/questions/64255505/less-js-error-evaluating-function-min-incompatible-types
    @sizeLabelHeight: ~"max(4vh, 40px)";

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    aspect-ratio: 1;
    height: @sizeLabelHeight;
    line-height: @sizeLabelHeight;
    font-size: ~"max(1.7vh, 14px)";

    border-radius: 50%;
    background: #1e2340;
    color: white;
    font-family: 'open-sans';
    // Having it inside the carousel looks a bit better but introduces a
    // catastrophic edge case state bug with hover state when starting/stopping
    // drag with cursor overlapping the floating size label.
    // so for now let's just move it out.
    // margin-left: 50px;
    opacity: 0.6;
    user-select: none;
  }

  .carouselSizeLabel {
    left: -2vh;
  }

  .sortHandButton {
    right: -2vh;

    &:active {
      background-color: #0e101e;
    }
  }
}

.widthLimiter.wider {
  width: 80%;
}

.carouselBackground {
  background-color: #1d1d1d4d;
  height: 100%;
  width: 100%;
  border-radius: 6vh;
  box-sizing: border-box;
  padding-top: 1vh;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid #1c1e3a7d;

  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.carouselFilter {
  outline: none;
  position: absolute;
  top: 0px;
  right: 0px;
}

.carouselLabel {
  width: min-content;
  position: absolute;
  left: 0;
  top: 2vh; // for now going with top-aligned instead of vertically centered
  // top: 50%;
  // transform: translateY(-50%);
  margin-left: 20px;
  color: white;
  font-family: 'open-sans';
  padding: 5px 10px;
  border-radius: 5px;
  user-select: none;
}

.topLabel {
  color: white;
  font-family: 'open-sans';
  font-size: 12px;
  display: inline;
  position: relative;
  top: -50%;
  left: -30px;
  user-select: none;
}