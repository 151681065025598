.settingsContainer {
  height: 80vh;
  overflow-y: scroll;

  color: #dedbe6;
  font-family: 'open-sans', sans-serif;
  border-radius: 5px;

  background-color: #7d758b57;
  backdrop-filter: blur(10px);
  box-shadow: 0px 3px 7px #00000070;

  width: 100%;
  padding: 35px;
  padding-left: 60px;
  margin-top: 15px;

  display: flex;
  flex-direction: row;
}

.saveSettingsChangesSnackbar {
  button.saveChangesButton, button.discardChangesButton {
    margin-left: 15px;
  }

  // hides the X to close since it shouldnt be an option - Save or Discard only
  .MuiAlert-action {
    display: none;
  }

  .MuiAlert-icon {
    font-size: 28px;
    flex-direction: column;
    justify-content: center;
  }
}