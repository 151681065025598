.playAreaCounter {
  width: fit-content;
  height: fit-content;
  position: absolute;
}


.animateTransitions {
  transition-property: left, bottom, transform;
  transition-duration: 0.3s;
  transition-delay: 0s;
}


.badge {
  text-align: center;
  font-family: "Comfortaa", sans-serif;
  position: relative;
  width: 40px;
  height: 60px; 
  border-radius: 7px;
  display: inline-block;
  user-select: none;
  box-shadow: 1px 1px 1px #555;
}
.badge:before, .badge:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.badge:before {
  transform: rotate(60deg);
}
.badge:after {
  transform: rotate(-60deg);
}

.badge .circle {
  border: 2px solid #555;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  font-size: 18px;
  position: absolute;
  background: #fff;
  z-index: 10;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  line-height: 28px;
  color: #555;
}
.badge .circle i.fa {
  font-size: 2em;
  margin-top: 8px;
}
.badge .font {
  display: inline-block;
  margin-top: 1em;
}
.badge .ribbon {
  position: relative;
  border-radius: 4px;
  padding: 5px 10px 4px;
  width: fit-content;
  z-index: 11;
  color: #fff;
  bottom: 12px;
  transform: translateX(-50%);
  left: 50%;
  top: 35px;
  font-size: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  background: linear-gradient(to bottom right, #555 0%, #333 100%);
  cursor: default;
  white-space: nowrap;
}

.yellow {
  background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%);
  color: #ffb300;
}

.orange {
  background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%);
  color: #f68401;
}

.pink {
  background: linear-gradient(to bottom right, #F48FB1 0%, #d81b60 100%);
  color: #dc306f;
}

.red {
  background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%);
  color: #c62828;
}

.purple {
  background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
  color: #7127a8;
}

.teal {
  background: linear-gradient(to bottom right, #4DB6AC 0%, #00796B 100%);
  color: #34a297;
}

.blue {
  background: linear-gradient(to bottom right, #4FC3F7 0%, #2196F3 100%);
  color: #259af3;
}

.blue-dark {
  background: linear-gradient(to bottom right, #1d80e3 0%, #283593 100%);
  color: #1c68c5;
}

.green {
  background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%);
  color: #7cb342;
}

.green-dark {
  background: linear-gradient(to bottom right, #4CAF50 0%, #1B5E20 100%);
  color: #00944a;
}

.silver {
  background: linear-gradient(to bottom right, #E0E0E0 0%, #999898 100%);
  color: #9e9e9e;
}

.gold {
  background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%);
  color: #b7892b;
}

footer {
  text-align: center;
  margin: 5em auto;
}
footer a {
  text-decoration: none;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #111;
  border: 0.5px dashed #f8f8f8;
  color: #999;
  margin: 5px;
}
footer a:hover {
  background: #222;
}
footer a .icons {
  margin-top: 12px;
  display: inline-block;
  font-size: 20px;
}