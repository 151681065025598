.battlefieldChatOuterContainer {
  width: 100%;
  height: 100%;
}

.battlefieldChatInnerContainer {
  height: calc(100% - 14px);
  width: calc(100% - 20px);
  // inline-block to prevent margin collapsing with outerContainer, see here:
  // https://stackoverflow.com/questions/9519841/why-does-this-css-margin-top-style-not-work
  display: inline-block;

  background-color: #304771;
  // border: 1px solid grey;
  margin: 7px;
  padding: 7px;
  border-right: none;
  border-radius: 14px;
  box-sizing: border-box;

  .chatFlexbox {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .chatLog {
    height: 85%;
    flex-grow: 1;

    font-size: calc(14px + 0.15vw);
    
    font-family: 'open-sans';
    color: white;
    text-align: left;

    overflow-y: scroll;
    overflow-x: auto;

    margin-bottom: 7px;
    padding-bottom: 7px;
    padding-right: 2px;

    .chatMessageSeries {
      padding: 8px;
      padding-top: 4px;

      .usernameHeader {
        font-size: calc(12px + 0.15vw);
        margin-bottom: 3px;
        color: #ffffff99;
      }

      &.userMessages {
        text-align: right;
        background-color: #01010130;
        border-radius: 4px;
      }

      &.opponentMessages {

      }

      &.actionLogMessages {
        text-align: center;
        @media screen and (max-width: 1500px) {
          font-size: calc(12px);
        }
        @media screen and (min-width: 1501px) {
          font-size: calc(12px + 0.1vw);
        }
      }

      .chatMessage {
        margin-top: 5px;
        overflow-wrap: anywhere;
      }

      .importantMessage {
        color: #ffc758;
        font-size: calc(14px + 0.2vw);
      }

      a, .hoverableCardReference {
        color: #aad2e8;
      }

      .username-red {
        color: #ff6363;
      }

      .username-blue {
        color: #929cff;
      }

      .username-green {
        color: #5fff5f;
      }

      .username-cyan {
        color: #00fdff;
      }

      .username-yellow {
        color: yellow;
      }

      .username-pink {
        color: #ffb0fe;
      }

      .username-orange {
        color: orange;
      }

      .username-magenta {
        color: #f427a0;
      }
    }
  }

  .chatInput {
    height: 32px;
    background-color: white;
  }
}