div.shortcutsTable {
  width: 400px;

  background-color: transparent;
  box-shadow: none;

  * {
    color: white;
  }

  .keyboardShortcutRow:hover {
    cursor: pointer;
    background-color: #9b999945;
  }
}

.shortcutsTableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button.resetDefaultsButton {
    font-family: 'open-sans', sans-serif;
    background-color: #3f51b5;
  }
}
