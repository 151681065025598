.rightSidebar {
  // background: linear-gradient(0deg, rgb(26 94 149) 25%, rgb(220 10 64) 100%);
  background: #1c2c49;;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end:  4;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 22vh 56vh 22vh;

  text-align: center;
}

.topThird {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  display: flex;
  align-items: center;
  justify-content: center;
}

.middleThird {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.bottomThird {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  // highly specific selector to override other stuff
  button.utilityButton {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#66abe5), to(#015490));
    height: 30%;
    min-height: 40px;
    max-height: 65px;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 0px 3px 8px #000000d4, inset 0px 2px 3px #58a4cc;
    color: white;

    &:hover {
      background-color: white;
    }

    &:active {
      box-shadow: 0px 2px 3px #3a3838 inset;
      padding-top: 10px;
    }
  }
}

// we want this to apply to all of them, left and right sidebar as well as menu button
button.utilityButton {
  svg {
    height: ~"max(1em, 70%)";
    // override the 1em they are getting from Material-UI CSS by just unsetting it,
    // since it was confining the height by proxy. Their css might be setting aspect-ratio.
    width: unset;
  }
}