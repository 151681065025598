.accountContainer {
  color: #dedbe6;
  font-family: 'open-sans', sans-serif;
  border-radius: 5px;

  background-color: #7d758b57;
  backdrop-filter: blur(10px);
  box-shadow: 0px 3px 7px #00000070;

  width: 100%;
  padding: 25px;
  margin-top: 15px;

  display: flex;
  flex-direction: row;

  .avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: fit-content;
    padding: 0px 50px 0px 25px;

    button {
      margin-top: 15px;
    }

    .playerAvatar {
      aspect-ratio: 1;
      width: 150px;
  
      box-shadow: 2px 3px 3px #00000096;
      border-radius: 50%;
    }
  }

  .accountDetailsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    padding-top: 5px;

    .labelsContainer {
      .usernameLabel {
        font-size: 24px;
      }

      div {
        margin-bottom: 15px;

        &:last-of-type {
          color: #a1bfdd;
        }
      }
    }
  
    .buttonsContainer {
      margin-right: 25px;

      button {
        display: block;
        background-color: #3f51b5;
        font-family: 'open-sans', sans-serif;

        &:last-of-type {
          margin-top: 15px;
        }
      }
    }
  }
}

// in the jsx it's a child of the account page but in the resulting html it is not
.simplePromptDialog {
  width: 35%;
  min-width: 400px;
  padding: 20px;
}

.gravatarDialog {
  font-family: 'open-sans', sans-serif;
}