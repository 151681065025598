.deckManagerControls {
  color: white;
  height: 30px;
  border-radius: 5px;
  margin-bottom: 20px;

  display: flex;
  justify-content: end;

  .MuiTouchRipple-rippleVisible {
    display: none;
  }

  .deckManagerColorFilters {
    width: fit-content;
    font-family: 'open-sans';
    display: inline-flex;
    align-items: center;
    margin-right: 15px;

    .colorFilterStrategy {
      margin-left: 10px;

      .rfs-select-container {
        display: inline-block;
        width: 130px;
      }

      .colorStrategyTooltip {
        display: inline;
      }
    }

    .colorFiltersLabel {
      font-size: 16px;
      margin-right: 5px;
      color: #edebf2;
    }

    span.MuiCheckbox-root {
      padding: 0;
    }
  }

  .createDeckButton {
    margin-right: 30px;
    font-family: 'open-sans', sans-serif;
    background-color: #3f51b5;
  }

  .nameFilter {
    padding-left: 5px;
    margin-right: 30px;

    &:focus {
      outline: none;
    }
  }

  .rfs-select-container {
    width: 140px;

    * {
      color: white;
      font-family: 'open-sans', sans-serif;
      box-shadow: none;
    }

    .rfs-option {
      color: black;
    }
  }

  .rfs-control-container {
    min-height: unset;
  }

  .rfs-control-container > div > div {
    overflow: visible;
  }
}