.playArea .playerSelectContainer {
  z-index: 90000;
  position: absolute;
  right: 0;
  top: 0;

  margin: 1.2vh;
  margin-right: 15px;

  label {
    margin-right: 5px;
    font-family: 'open-sans', sans-serif;
  }
}

*:focus {
  outline: 0;
}