.deckSelector {
  display: inline-block;
  color: black;

  .rfs-select-container {
    width: 200px;
    border-radius: 5px;
    background-color: #edebf2;
  }

  .rfs-control-container > div > div {
    overflow: visible;
  }
}