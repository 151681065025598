.waitingRoomListItem {
  color: #dedbe6;
  background-color: #7d758b57;
  backdrop-filter: blur(10px);
  box-shadow: 0px 3px 7px #00000070;

  border-radius: 5px;

  min-width: 420px;
  max-width: 700px;
  height: 110px;
  padding: 15px 20px;
  margin-bottom: 15px;
  margin-right: 15px;
  
  font-family: 'open-sans', sans-serif;
  user-select: none;

  display: inline-grid;
  grid-template-columns: 1fr 20%;
  grid-template-rows: 1fr 1fr;

  .roomTitleContainer {
    grid-column-start: 1;
    grid-column-end: 2;

    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    align-items: flex-start;

    .roomTitle {
      user-select: text;
      font-size: 18px;
      width: 300px;
      height: 25px; // prevents overflow: hidden from clipping descenders
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .playerCountContainer {
    grid-column-start: 2;
    grid-column-end: 3;

    grid-row-start: 1;
    grid-row-end: 2;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    &.passworded {
      justify-content: space-between;
    }
  }

  .tagsContainer {
    & * {
      color: #dedbe6;
    }

    & > div {
      margin-right: 5px;
    }

    grid-column-start: 1;
    grid-column-end: 2;

    grid-row-start: 2;
    grid-row-end: 3;

    display: flex;
    align-items: flex-end;
  }

  .statusContainer {
    grid-column-start: 2;
    grid-column-end: 3;

    grid-row-start: 2;
    grid-row-end: 3;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    white-space: nowrap;
    margin-bottom: 10px;

    button {
      // offset so that the button's padding doesn't put it in a weird place
      // (removing the padding looks weird too)
      position: relative;
      top: 8px;
      right: -8px;
      padding-bottom: 0px;
    }
  }

  & svg {
    color: #dedbe6;
  }
}

