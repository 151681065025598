.battlefield {
  display: grid;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1500px) {
    grid-template-columns: clamp(250px, 30vh, 360px) minmax(0%, 1fr) clamp(220px, 30vh, 300px);
  }

  @media screen and (min-width: 1501px) {
    grid-template-columns: clamp(250px, 30vh, 500px) minmax(0%, 1fr) clamp(285px, 30vh, 450px);
  }

  grid-template-rows: 82vh 18vh;
}