@currentRoomColor: #221f2a;

.waitingRoomChatContainer {
  height: 100%;
  padding: 10px;
  border-radius: 20px;
  box-shadow: inset 0px 0px 0px 10px @currentRoomColor, 0px 0px 0px 8px @currentRoomColor;
  background-color: #4a4a4a42;

  .waitingRoomChatInnerContainer {
    width: 100%;
    height: 100%;

    position: relative;

    border: 1px solid #66618985;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .chatLog {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 48px;
    overflow-y: scroll;
    word-break: break-word;

    .chatMessageUserId {
      margin-right: 8px;
    }

    a {
      color: #8edada;
    }
  }

  .chatInput {
    position: absolute;
    bottom: 8px;
    left: 8px;
    right: 8px;
    height: 32px;
    background-color: #ffffffb0;

    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
}