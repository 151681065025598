div.editDeckDialogContainer {
  color: #0f0a51;
  background: #ececf5;
  border-radius: 8px;

  max-width: fit-content;

  * {
    box-sizing: border-box;
    font-family: 'open-sans', sans-serif !important;
  }

  h2 {
    text-align: center;
    padding-bottom: 10px;
  }

  .editDeckDialogContent {
    width: fit-content;
    height: fit-content;
    padding: 0px 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .nameAndFormatContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 15px;

      .nameContainer, .formatContainer {
        display: inline-block;
      }

      .nameLabel, .formatLabel {
        display: inline-block;
        margin-bottom: 10px;
      }

      .nameInput {
        display: block;
        font-size: 16px;
        height: 38px;
        padding-left: 10px
      }

      .rfs-select-container {
        width: 150px;
        background-color: white;
      }

      .rfs-control-container {
        border: 1px solid #767676;

        & > div > div {
          overflow: visible;
        }
      }
    }

    .instructionsLabel {
      align-self: flex-start;
      margin-bottom: 10px;
    }

    .errorMessage {
      padding-left: 0;
      align-self: flex-start;
      width: 400px;

      background-color: #ececf5;
      color: red;

      .MuiAlert-icon {
        display: flex;
        align-items: center;
      }
    }

    .textareasContainer {
      display: flex;
      justify-content: space-between;
      min-width: 850px;

      .decklistContainer, .sideboardContainer {
        position: relative;
      }

      .deckSize, .sideboardSize {
        position: absolute;
        right: 10px;
      }

      textarea {
        resize: none;
        font-size: 16px;

        height: 300px;
        width: 48%;
        min-width: 400px;

        padding: 10px;
      }
    }

    .decklistInput {

    }

    .buttonRow {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 25px;

      button {
        display: inline-block;
      }

      .deleteButton {
        background-color: #a84a4a;
      }

      .saveButton {
        background-color: #3f51b5;
      }
    }
  }
}