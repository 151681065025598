.otherPlayerLibrary {
  padding: clamp(15px, 1.5vh, 30px);

  // override style from Pile.less because this isn't clickable unlike others
  .pileSizeLabel {
    cursor: default;

    // overriding the hover styles on the other piles
    &:hover {
      cursor: default;
      background: #3d3d3d;
      border: 2px solid #666;
      border-left: none;
      border-bottom: none;
    }
  }
}