.carouselCard {
  background-size: 100% 100%;
  display: inline-block;
  height: 14vh;
  width: calc(14vh / 1.4);
  border-radius: 5%; // for the opaque corners of scryfall's `normal` card image
}

.carouselHoverGhost {
  background-size: 100% 100%;
  display: inline-block;
  height: 14vh;
  width: calc(14vh / 1.4);
}

.carouselCardWrapper {
  display: inline-block;

  // could probably remove the width and height from the other two classes but not right now
  height: 14vh;
  width: calc(14vh / 1.4);
  // Adding the overlap between cards might remove the need for some of the buffer logic
  // around the preview, but maybe better to leave it as a safety net
  margin-left: -10px;
}

.facedownCarouselCard {
  background-image: url("../../../../shared/card-back.png");
}