.lobbyNavigation {
  padding-top: ~"max(20px, 4vh)";

  li {
    display: block;
    color: #edebf2;
    font-family: 'open-sans', sans-serif;

    @media screen and (max-width: 1500px) {
      font-size: 16px;
    }
    @media screen and (min-width: 1501px) and (max-width: 2000px) {
      font-size: 18px;
    }
    @media screen and (min-width: 2001px) {
      font-size: 20px;
    }

    a:hover {
      color: #e1aa51;

      * {
        color: #e1aa51;
      }
    }

    a {
      color: #edebf2;
      text-decoration: none;
      display: inline-block;

      // slightly more spaced out on big screens
      @media screen and (max-height: 1200px) {
        height: 50px;
        line-height: 50px;
      }

      @media screen and (min-height: 1201px) {
        height: 60px;
        line-height: 60px;
      }

      padding-left: ~"max(20px, 4vh)";

      &.activeNav {
        color: #468ee7;
      }

      // for icons
      svg {
        vertical-align: middle;
        margin-right: 20px;
      }

      .discordLogo {
        width: 24px;
        height: 24px;
      }
    }
  }
}