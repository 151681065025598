div.addCardDialogContainer { // need the "div." to exceed the specifity of the mui rules
  background: #28234ab8;
  color: white;
  border-radius: 12px;
  overflow: visible;
  padding-left: 10px;
}

.addCardDialogTitle * {
  font-family: 'open-sans', sans-serif !important;
}

.addCardDialogTitle h2 {
  margin-bottom: 10px;
}

.addCardDialogContent {
  width: 40vw;
  max-width: 100%;
  height: fit-content;
  padding: 0px 24px;
  font-family: 'open-sans', sans-serif;

  .cardSearch {
    margin-bottom: 20px;

    &:focus {
      // outline: none;
    }

    // without this the input text looks like it has a shadow because it gets doubled up
    & label {
      display: none;
    }

    & fieldset {
      border: none;
    }
  }

  .quantityContainer {
    margin-bottom: 20px;
  }

  #quantity {
    width: 77px;
    margin-left: 40px;
    padding-left: 5px;
  }

  .destinationContainer {
    margin-bottom: 20px;
  }

  #destination {
    padding: 1px 5px;
    margin-left: 10px;
  }

  .isTokenContainer {
    margin-bottom: 35px;
  }
 
  #isToken {
    display: inline-block;
    margin-left: 0px;
  }

  .isTokenLabel {
    margin-bottom: 5px;
    display: inline-block;
    margin-left: 5px;
  }

  .isTokenFootnote {
    display: block;
    margin-bottom: 10px;
  }

  #submit {
    display: block;
    margin-bottom: 30px;
  }
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}