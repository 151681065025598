@import url("../../../../../../shared/variables.less");

.magnifiedCard {
  position: absolute;
  background-size: 100% 100%;
  display: inline-block;
  height: ~"max(28vh, 350px)";
  width: ~"calc(max(28vh, 350px) / 1.4)";
  z-index: @drag-zindex;
  border-radius: 13px;
}

.aboveDialog {
  z-index: @aboveDialog-zindex;
}