.decksList {
  color: #edebf2;
  font-family: 'open-sans', sans-serif;

  position: relative;
  display: grid;

  @media screen and (max-width: 1650px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1651px) and (max-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media screen and (min-width: 2201px) and (max-width: 3000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (min-width: 3001px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  height: fit-content;
  max-height: 78vh;
  min-width: 840px;
  padding-top: 10px;
  padding-left: 25px;

  overflow-y: auto;
  overflow-x: hidden;

  &.emptyState {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .emptyStateMessage {
    font-size: 18px;
    
    height: fit-content;
    position: relative;
    top: 35%;

    .emptyStateMainLabel {
      margin-bottom: 8px;
    }
  }

  .emptyStateCreateButton {
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
    margin: auto;
  }
}