.playArea {
  background: linear-gradient(0deg, #e47f65, #6359da);

  position: relative;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end:  2;

  &:before, &:after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
  }
  &:before {
    box-shadow: -10px 0px 15px -15px inset;
    right: 0px;
  }
  &:after {
    box-shadow: 10px 0px 15px -15px inset;
    left: 0px;
  }
}

.myPlayArea {
  height: 50%;
  // border-top: 1px solid #807c7c;
}

.theirPlayArea {
  height: 50%;
}

.playArea button.utilityButton {
  z-index: 90000;
  position: absolute;
  left: 0;
  top: 0;

  width: 38px;
  height: 38px;
  line-height: 36px;
  margin: 1.2vh;
  margin-left: 15px;
  margin-right: 9px;

  background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
  border-radius: 50%;
  box-shadow: 0px 3px 8px #3a3838, inset 0px 2px 3px #fff;
  
  &:hover {
    background-color: white;
  }

  &:active {
    box-shadow: 0px 2px 3px #3a3838 inset;
    padding-top: 10px;
  }
}