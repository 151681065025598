@import '../../../../shared/variables.less';

.contextMenu {
  position: absolute;
  z-index: @contextMenu-zindex;
  background-color: white;
  color: black;
  // font-family: 'Roboto', sans-serif;
  font-family: open-sans, sans-serif;
  width: fit-content;
  border-radius: 5px;
  padding: 3px;
  box-shadow: 1px 1px 5px 0px #000000ab;
  opacity: 0.92;

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.contextMenuItem {
  border-radius: 4px;
  padding: 3px 10px;
  user-select: none;

  &:hover {
    background-color: #def0ff;
  }

  // used mainly for color picker, but also locked options
  &.disabledMenuOption:hover {
    background-color: white;
  }

  &.delete {
    color: red;
  }
}

.disabledMenuOption {
  color: lightgrey;
}

.colorPickerGridContainer {
  display: grid;
  grid-template-columns: 18px 18px 18px 18px 18px 18px;
  grid-template-rows: 18px 18px;
}

.colorPickerOption {
  height: 18px;
  width: 18px;

  // these colors are actually overridden by the classes applies to the counters,
  // and thus have a nice gradient instead. just commenting it out for now.
  // &.red {
  //   background-color: red;
  // }
  // &.yellow {
  //   background-color: yellow;
  // }
  // &.orange {
  //   background-color: orange;
  // }
  // &.blue {
  //   background-color: lightblue;
  // }
  // &.blue-dark {
  //   background-color: blue;
  // }
  // &.green {
  //   background-color: #8bc34a;
  // }
  // &.green-dark {
  //   background-color: darkgreen;
  // }
  // &.teal {
  //   background-color: teal;
  // }
  // &.gold {
  //   background-color: gold;
  // }
  // &.silver {
  //   background-color: silver;
  // }
  // &.purple {
  //   background-color: purple;
  // }
  // &.pink {
  //   background-color: pink;
  // }
}